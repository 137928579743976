import { Stage, IDP } from 'src/constants/constants';

export function getStage(): Stage {
  // beta will be replaced during deployment by Katal Deployment Lambda with appropriate stage value.
  const stage = process.env.NODE_ENV !== 'production' ? Stage.DEV : (`beta` as Stage);
  return stage;
}

/**
 * This method can be used to identify IDP in the future.
 */
export function getIDP(): IDP {
  const domainURL = window.location.href;
  // domainURL for disney - https://disney.alexaservices.com
  if (domainURL.includes('disney')) {
    return IDP.disneyMyID;
  }
  return IDP.amazonFederate;
}
